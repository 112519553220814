<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.95455 0C5.15122 0 4.5 0.666366 4.5 1.48837V3.34884H1.40909C0.907011 3.34884 0.5 3.76531 0.5 4.27907C0.5 4.79283 0.907011 5.2093 1.40909 5.2093H2.42513L2.96492 14.599C3.01015 15.3857 3.64681 16 4.41695 16H12.5831C13.3532 16 13.9899 15.3857 14.0351 14.599L14.5748 5.2093H15.5909C16.093 5.2093 16.5 4.79283 16.5 4.27907C16.5 3.76531 16.093 3.34884 15.5909 3.34884H12.5V1.48837C12.5 0.666366 11.8488 0 11.0455 0H5.95455ZM6.68182 7.06977C7.18393 7.06977 7.59091 7.48621 7.59091 8V11.3488C7.59091 11.8626 7.18393 12.2791 6.68182 12.2791C6.17974 12.2791 5.77273 11.8626 5.77273 11.3488V8C5.77273 7.48621 6.17974 7.06977 6.68182 7.06977ZM11.2273 8C11.2273 7.48621 10.8203 7.06977 10.3182 7.06977C9.81607 7.06977 9.40909 7.48621 9.40909 8V11.3488C9.40909 11.8626 9.81607 12.2791 10.3182 12.2791C10.8203 12.2791 11.2273 11.8626 11.2273 11.3488V8ZM6.31818 3.34884V1.86047H10.6818V3.34884H6.31818Z"
    />
  </svg>
</template>
